<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
    <div class="vx-col">
      <div class="vx-row no-gutter justify-center items-center">
        <div class="vx-col lg:block">
          <center>
            <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
          </center>
        </div>

        <div class="vx-col mx-auto lg:w-3/4">
          <div class="p-8 login-tabs-container">
            <div class="mb-4">
              <button class="oeno_portfolio_title_small bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l float-right" @click="logout()">Logout</button>
              <h1 class="text-4xl oeno_portfolio_title">{{ getUserName }}</h1>
              <h3 class="text-4xl oeno_portfolio_title_small">
                {{ getUserReference }}<br /><br />
              </h3>
              <hr class="oeno_divider" />
              <h1 class="text-4xl"><i>My Account</i><br /><br /></h1>
              <p class="oeno_portfolio_text">
                The prices shown are estimates and could change when you come to
                sell your wines.<br />
                Your broker : {{ getUserBroker }}
              </p>
            </div>

            <span id="download_loader" v-if="!$store.state.portfolio"></span>

            <div v-if="$store.state.portfolio">
              <div v-for="total in getTotals" v-bind:key="total.currency" class="grid grid-cols-5 gap-4 pb-2">
                <div><span class="oeno_portfolio_total_currency">{{ total.currency }}</span></div>
                <div class="col-span-3"></div>
                <div class="">
                  <span class="oeno_portfolio_total_total float-right">{{ currencyFormatter(total.total) }}</span><br clear="all"/>
                  <span :class="[
                    (total.changePrice >= 0)
                      ? 'oeno_portfolio_price_up  float-right'
                      : 'oeno_portfolio_price_down  float-right',
                  ]">{{ getChangeTotal(total) }}</span>
                </div>
              </div>
              <div class="mt-10 mb-10">
                <h1 @click="showAnalytics()" class="text-3xl oeno_portfolio_title font-bold float-right cursor-pointer">Analytics</h1>
                <h1 class="text-5xl"><i>Portfolio</i></h1>
              

              </div>
              <div v-for="investment in $store.state.portfolio" v-bind:key="investment.id">
                <div v-for="item in investment.items" v-bind:key="item.id" class=""
                @click="showDetail(item.wine_id, investment.id)" >
                  <div v-if="item.quantity2>=1" class="grid grid-cols-5 gap-4 cursor-pointer" key="" >
                  <div>
               
            
                    <img :src="wineImage(item)" class="oeno_portfolio_wineimage"  onerror="this.style.display='none'"/>
                  </div>
                  <div class="col-span-3">
                    <span class="oeno_portfolio_winetitle">{{
                        item.wines.name
                    }}</span><br />
                    <span class="oeno_portfolio_winesubtitle">{{
                        winary(item)
                    }}</span><br />
                    <span class="oeno_portfolio_winequantity">{{
                        showquantity(item)
                    }}</span>

                  </div>

                  <div>
                    <span class="float-right oeno_portfolio_price">{{ currencyFormatter(getPrice(investment, item)) }}&nbsp;{{ getCurrency(investment, item) }}</span>
                    <br /><span :class="[
                      priceUp(investment, item)
                        ? 'float-right oeno_portfolio_price_up'
                        : 'float-right oeno_portfolio_price_down',
                    ]">{{ getChange(investment, item) }}</span>
                  </div>
                  <div class="col-span-5">
                    <hr class="oeno_divider_sm" />
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import moment from "moment";

export default {
  data() {
    return {
    };
  },
  mounted() {
    this.$nextTick(() => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.get("/api/get-portfolio-full", { headers: headers }).then(
        (response) => {
          if (response.data.statuscode == 200) {
            this.$store.dispatch('storePortfolioData', { portfoliodata: response.data.data.investment });
          }
        }
      );
    })
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    getUserBroker() {
      var broker = "";
      if (this.$store.state.user) {
        broker =
          this.$store.state.user.brokerName +
          " " +
          this.$store.state.user.brokerEmail;
      }
      return broker;
    },
    getTotals() {
      const values = [];

      this.$store.state.portfolio.forEach((investment) => {
        investment.items.forEach((item) => {
          var itemPrice = this.getValuesForItem(investment, item);

          var pos = -1;
          for (let index = 0; index < values.length; index++) {
            if (values[index].currency == itemPrice.currency) {
              pos = index;
            }

          }

          if (pos > -1) {

            values[pos].total = values[pos].total + parseFloat(itemPrice.total);
            values[pos].changePrice = values[pos].changePrice + parseFloat(itemPrice.changePrice);

          } else {

            values.push({
              currency: itemPrice.currency,
              changePrice: parseFloat(itemPrice.changePrice),
              total: itemPrice.total,
            });

          }

        });
      });
      return values;
    },
  },
  methods: {
    logout(){
      this.$store.dispatch('login', {token: ''}) 
      this.$router.push('/login')
      this.$store.dispatch('storePortfolioData', {  });
    },
    showDetail(id, orderId) {
      this.$router.push({ name: "page-portfolio-detail", params: { id: id, orderId: orderId } });
    },
    showAnalytics(){
      this.$router.push("/analytics");
    },
    winary(wine) {
      var winary = "n/a";
      var vintage = "";
      var size = "";

      if (wine.wines) {
        if (wine.wines.winery) {
          vintage = wine.wines.vintage;
          if (wine.wines.winery.name) {
            winary = wine.wines.winery.name;
          }
          if (wine.wines.size.name) {
            size = wine.wines.size.name;
          }
        }
      }

      winary = winary + " " + vintage;

      if (size != "") {
        winary = winary + " " + size + "ML";
      }

      return winary;
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity2);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    wineImage(wine) {
      var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;
      // console.log(wine)
      if (wine.wines) {


        if (wine.wines.images) {

          if (wine.wines.images[0]) {
            wineimageurl =
              process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
          }
        }
      }

      return wineimageurl;
    },

    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }


      var buytotal =
      wine.quantity2 *
            wine.wines.units_per_case * wine.bottle_price;

      var total =
        parseInt(
          parseFloat(
            (wine.total_bottles-wine.total_unsold_brokerage) *
          
            wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

// console.log(total, wine.livePrices.data["price-average"],  wine.wines.units_per_case, wine.bottle_price, wine.quantity2 )
      if (wine.livePrices.data["price-average"] == 0 ) {
        total = buytotal;
      } 

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc = 
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) * 100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
      };
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total;
    },

    getCurrency(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.currency;
    },

    getChange(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return this.currencyFormatter(priceValues.changePrice) + "(" + this.currencyFormatter(priceValues.changePerc) + "%)";
    },

    getChangeTotal(total) {


      var changePerc = (total.changePrice / (total.total-total.changePrice)) * 100;

      return this.currencyFormatter(total.changePrice) + "(" + changePerc.toFixed(2) + "%)";
    },

    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },

    currencyFormatter(value) {
      const options = {
        significantDigits: 2,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        symbol: "",
      };

      if (typeof value !== "number") value = parseFloat(value);
      value = value.toFixed(options.significantDigits);

      const [currency, decimal] = value.split(".");
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}${options.decimalSeparator}${decimal}`;
    },
  },
};
</script>

<style>
#download_loader {
  width: 100px;
  height: 100px;
  background-image: url(/dotloader.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin: auto;
  background-size: 100%;
}
</style>
